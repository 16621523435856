const urls = {
  appXls:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/forms/APP.xlsx',
  avpPdf:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/forms/FM-PSD-01.pdf',
  gcashImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/gcashqr.jpg',
  logoImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/logoImg.png',
  userImg:
    'https://storage.googleapis.com/bucket-cpa-permits-prod/images/header/useImg.png',
};

export default urls;
