import { Box, Card, CardContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import formState from 'common/data/formState';

import CheckIcon from 'svg/Home/CheckIcon';
import EighthPermitIcon from 'svg/Home/EighthPermitIcon';
import FifthPermitIcon from 'svg/Home/FifthPermitIcon';
import FifteenthPermitIcon from 'svg/Home/FifteenthPermitIcon';
import FirstPermitIcon from 'svg/Home/FirstPermitIcon';
import FourteenthPermitIcon from 'svg/Home/FourteenthPermitIcon';
import FourthPermitIcon from 'svg/Home/FourthPermitIcon';
import NinthPermitIcon from 'svg/Home/NinthPermitIcon';
import SecondPermitIcon from 'svg/Home/SecondPermitIcon';
import SeventeenthPermitIcon from 'svg/Home/SeventeenthPermitIcon';
import SeventhPermitIcon from 'svg/Home/SeventhPermitIcon';
import SixteenthPermitIcon from 'svg/Home/SixteenthPermitIcon';
import SixthPermitIcon from 'svg/Home/SixthPermitIcon';
import TenthPermitIcon from 'svg/Home/TenthPermitIcon';
import ThirdPermitIcon from 'svg/Home/ThirdPermitIcon';
import ThirteenthPermitIcon from 'svg/Home/ThirteenthPermitIcon';
import TwelvethPermitIcon from 'svg/Home/TwelvethPermitIcon';

const permitIcons = {
  1: FirstPermitIcon,
  2: SecondPermitIcon,
  3: ThirdPermitIcon,
  4: FourthPermitIcon,
  5: FifthPermitIcon,
  6: SixthPermitIcon,
  7: SeventhPermitIcon,
  8: EighthPermitIcon,
  9: NinthPermitIcon,
  10: TenthPermitIcon,
  12: TwelvethPermitIcon,
  13: ThirteenthPermitIcon,
  14: FourteenthPermitIcon,
  15: FifteenthPermitIcon,
  16: SixteenthPermitIcon,
  17: SeventeenthPermitIcon,
};

const PermitCard = ({ id, name, onSelectPermit }) => {
  const [values, setValues] = useRecoilState(formState);
  const [isHover, setHover] = useState(false);
  const PermitIcon = permitIcons[id];

  const { user } = values;
  const isLoggedIn = Boolean(user);

  if (!PermitIcon) {
    return null;
  }

  return (
    <Card
      className={cn('Home_permitCard', { isHover, isLoggedIn })}
      elevation={2}
      onClick={() => {
        if (isLoggedIn) {
          setValues(state => ({
            ...state,
            parentUrl: 'Home',
            permitType: { id, name },
            permitApplicationStatus: null,
          }));
          onSelectPermit(id);
        }
      }}
      onMouseLeave={() => {
        if (isLoggedIn) {
          setHover(false);
        }
      }}
      onMouseOver={() => {
        if (isLoggedIn) {
          setHover(true);
        }
      }}
    >
      <CardContent>
        <Box className="Home_cardContent">
          {isHover && (
            <Box left="-17px" position="absolute" top="-17px">
              <CheckIcon />
            </Box>
          )}
          <Box mb={3}>
            <PermitIcon />
          </Box>
          <Box className={cn('Home_permitLabel', { isHover })}>
            <Typography
              sx={{ fontWeight: isHover ? 700 : 500 }}
              variant="body2"
            >
              {name}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

PermitCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSelectPermit: PropTypes.func.isRequired,
};

export default PermitCard;
