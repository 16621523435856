const translations = {
  forms: {
    apvFormTitle: 'APPLICATION FOR ANNUAL VEHICLE PASS',
    fields: {
      documentNo: 'Document No.',
      revisionNo: 'Revision No.',
      effectiveDate: 'Effective Date',
    },
  },
  payments: {
    gcash: {
      description:
        'To Complete your payment for the Cebu Port Authority’s online permits application, please scan the QR Code using your GCash App',
      important: 'Important:',
      keepCopy:
        'Keep a copy of your transaction receipt. You may need to upload it as part of your online permit application.',
      steps: [
        '1. Open GCash app on your phone',
        <span>
          2. Tap on “<span style={{ color: '#096BEF' }}>Pay QR</span>” and scan
          the code
        </span>,
        '3. Enter the payment amount and confirm the transaction',
      ],
      stepsPay: ' STEPS TO PAY',
      title: 'PAY WITH GCASH',
    },
  },
};

export default translations;
