import { useRecoilState, useSetRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

import './styles.scss';
import { dateFormat } from 'common/utils/dateConvert';
import { deleteItemModal } from 'common/data/modalState';
import AttachmentIcon from 'svg/Permits/AttachmentIcon';
import formState from 'common/data/formState';

const statusDisplay = {
  Approved: { bgcolor: '#f1fff8', color: '#2bb673' },
  Draft: { bgcolor: '#e3e3e3', color: '#ffffff' },
  'In Review': { bgcolor: '#096bef', color: '#ffffff' },
  New: { bgcolor: '#a3a3a3', color: '#ffffff' },
  Rejected: { bgcolor: '#ffeeee', color: '#ff0000' },
  Pending: { bgcolor: '#fff7f0', color: '#ff7a00' },
};

const PermitLists = ({
  applicationStartDate,
  permitApplicationLogId,
  permitType,
  status,
  submittedDocumentsCount,
  onSelectPermit,
}) => {
  const [values, setValues] = useRecoilState(formState);
  const setOpenDeleteModal = useSetRecoilState(deleteItemModal);
  const statusFormat = statusDisplay[status];
  const displayDate = dateFormat(applicationStartDate);

  const { user } = values;
  const isLoggedIn = Boolean(user);

  return (
    <Paper elevation={2}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        p={4}
      >
        <Box display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" flexDirection="row">
            <Box
              sx={{
                bgcolor: statusFormat.bgcolor,
                borderRadius: 2,
                color: statusFormat.color,
                mr: 2,
                px: 3,
                py: 1,
                textAlign: 'center',
                textTransform: 'uppercase',
                width: 80,
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                {status}
              </Typography>
            </Box>
            <Box sx={{ color: '#9e9e9e', textTransform: 'uppercase' }}>
              <Typography sx={{ fontWeight: 400 }} variant="caption">
                {displayDate}
              </Typography>
            </Box>
          </Box>
          <Box py={2}>
            <Typography
              sx={{ color: '#010046', fontWeight: 500 }}
              variant="body1"
            >
              {permitType.name}
            </Typography>
          </Box>
          <Box color="#9e9e9e" display="flex">
            <AttachmentIcon />
            <Typography sx={{ px: 1 }} variant="caption">
              {String(submittedDocumentsCount).concat(' attachment(s)')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Button
            component="label"
            onClick={() => {
              if (isLoggedIn) {
                setValues(state => ({
                  ...state,
                  parentUrl: 'Permits',
                  permitType: {
                    id: permitType.permitTypeId,
                    name: permitType.name,
                  },
                  permitApplicationStatus: status,
                }));
                onSelectPermit(permitType.permitTypeId, permitApplicationLogId);
              }
            }}
            sx={{
              background: 'linear-gradient(90deg, #77D2FF 0%, #489DFF 91.89%)',
              mb: 2,
              px: 5,
            }}
            variant="contained"
          >
            VIEW
          </Button>

          {status !== 'Approved' && (
            <Button
              className="PermitLists_delete"
              component="label"
              onClick={() => {
                setValues(state => ({
                  ...state,
                  deleteId: permitApplicationLogId,
                  deleteName: permitType.name,
                }));
                setOpenDeleteModal(true);
              }}
            >
              DELETE
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

PermitLists.propTypes = {
  applicationStartDate: PropTypes.string.isRequired,
  onSelectPermit: PropTypes.func.isRequired,
  permitApplicationLogId: PropTypes.number.isRequired,
  permitType: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  submittedDocumentsCount: PropTypes.number,
};

export default PermitLists;
